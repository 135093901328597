<template>
  <div class="actCertificate">
    <div class="head">
      <div @click="$router.go(-1)">
        <svg-icon iconClass="leftArrow" class="leftArrow"></svg-icon>
      </div>
      <div>账号凭证</div>
    </div>
    <div class="body">
      <div class="certificate_title">防止账号丢失</div>
      <div class="appName">色中色</div>
      <div class="qrBox">
        <div class="qrcode" ref="qrCodeUrl" v-if="qrUrl"></div>
        <van-loading size="25px" type="spinner" color="red" v-else />
      </div>
      <div class="notice">为防止账号丢失，请务必保存</div>
      <div class="info">官网地址：sifspf.com</div>
      <div class="info">色中色ID：{{$store.getters.userInfo.id}}</div>
      <div class="tip">
        <p>账号凭证是除绑定的手机号外</p>
        <p>唯一可用于恢复账号的凭证</p>
      </div>
      <div class="remind">切勿丢失或泄露给他人</div>
      <div class="btn">请截图保存</div>
    </div>
  </div>
</template>

<script>
import QRCode from "qrcodejs2";
import { queryCertificate } from "@/api/mine/actCertificate.js";
export default {
  components: {},
  data() {
    return {
      qrUrl: "",
    };
  },
  created() {
    this.queryCertificate();
  },
  methods: {
    // 生成二维码
    generateCode(url) {
      this.$nextTick(() => {
        this.qrcode = new QRCode(this.$refs.qrCodeUrl, {
          text: url,
          width: 156,
          height: 156,
          colorDark: "#000000",
          margin: 60,
          colorLight: "#ffffff",
          correctLevel: QRCode.CorrectLevel.L,
        });
      });
    },

    async queryCertificate() {
      let ret = await this.$Api(queryCertificate);
      if (ret && ret.code == 200) {
        this.qrUrl = ret.data.certificate;
        this.generateCode(this.qrUrl);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.van-loading--spinner {
  margin: 0;
}
.leftArrow {
  position: absolute;
  top: 12px;
  left: 15px;
}
.actCertificate {
  .head {
    height: 44px;
    font-size: 20px;
    font-weight: 500;
    font-family: PingFangSC-Medium;
    color: #fff;
    background: $vermillion;
    text-align: center;
    line-height: 44px;
  }
}

.body {
  height: calc(100vh - 44px);
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
}
.certificate_title {
  font-size: 30px;
  color: #6f7298;
  text-align: center;
  margin: 15px 0 10px;
  font-weight: 400;
  color: rgba($color: #000000, $alpha: 0.57);
}

.appName {
  font-size: 14px;
  text-align: center;
  color: rgba($color: #000000, $alpha: 0.57);
}

.qrBox {
  height: 176px;
  width: 176px;
  margin: 20px auto 10px;
  background: linear-gradient($vermillion, $vermillion) left top,
    linear-gradient($vermillion, $vermillion) left top,
    linear-gradient($vermillion, $vermillion) right top,
    linear-gradient($vermillion, $vermillion) right top,
    linear-gradient($vermillion, $vermillion) right bottom,
    linear-gradient($vermillion, $vermillion) right bottom,
    linear-gradient($vermillion, $vermillion) left bottom,
    linear-gradient($vermillion, $vermillion) left bottom;
  background-repeat: no-repeat;
  background-size: 4px 20px, 20px 4px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.qrcode {
  width: 3.2rem;
  height: 3.2rem;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.notice {
  font-size: 19px;
  color: $vermillion;
  text-align: center;
  margin-top: 24px;
  margin-bottom: 16px;
  font-weight: bold;
}

.info {
  font-size: 14px;
  margin: 0 auto 10px;
  text-align: center;
  font-weight: 500;
}

.tip {
  font-size: 15px;
  text-align: center;
  line-height: 26px;
  padding: 10px 0;
  background: #f3f3f3;
  margin: 20px 20px 35px 20px;
  color: #000000;
  border-radius: 4px;
  font-weight: 500;
  p:nth-child(1) {
    letter-spacing: 1.5px;
  }
}

.remind {
  font-size: 14px;
  text-align: center;
  color: rgba($color: #000000, $alpha: 0.57);
}

.btn {
  height: 34px;
  line-height: 34px;
  font-size: 18px;
  border-radius: 18px;
  margin: 20px 30px;
  background: $vermillion;
  text-align: center;
  color: #fff;
}
</style>
